<template>
  <section>
    <iframe src="https://www.jq22.com/demo/jQuery-40420160406/"></iframe>
    <div @click="backPage" class="btn faj"></div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      inputType: "password",
    };
  },
  methods: {},
  created() {},
  mounted() {},
  computed: {},
  watch: {},
};
</script>

<style lang="less" rel="stylesheet/less" scoped>
iframe {
  position: absolute;
  display: block;
  border: none;
  height: 100%;
  width: 100%;
}
.btn {
  height: 100%;
  width: 100%;
  background: none;
  cursor: pointer;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
}
</style>